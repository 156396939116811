import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from 'gatsby-image'
import "../styles/showroom.css"
import "../styles/about.css"
import Price from "../components/Price"
import Input from "../components/form/Input";
import Slider from "react-slick";

export default ({ data }) => {
    const sliderOptions = {
        variableWidth: true,
        slidesToShow: 1,
        infinite: false,
        responsive: [{
            breakpoint: 900,
            settings: {
              slidesToShow: 1,
              centerMode: false,
              variableWidth: true,
              arrows: true
            }
        },
        {
          breakpoint: 600,
          settings: {
            variableWidth: false,
            slidesToShow: 1,
            arrows: true
          }
        }]
      }
    
  return (
    <>
        <SEO title={"Showroom"} />
        <section id="showroom">
            <h2 className="heading">Showroom</h2>
            <Img fluid={data.directusShowroom.main_image.localFile.childImageSharp.fluid} className="opaque50 about--image-top" />
            {data.directusShowroom.catch_text.length > 0 && <p className="about--catch">{data.directusShowroom.catch_text}</p>}
            <div className="opaque80 about--text" dangerouslySetInnerHTML={{__html: data.directusShowroom.text}}></div>
        </section>
            {data.directusShowroom.gallery !== null && (
            <section className="news--gallery">
                <Slider {...sliderOptions} className="news--gallery-slider">
                {data.directusShowroom.gallery.map((item, index) => {
                    return(
                    <figure className="news--gallery-item" key={index}>
                        <Img fluid={item.image.localFile.childImageSharp.fluid}/>
                        {item.name !== null && (
                        <figcaption>{item.name}</figcaption>
                        )}
                    </figure>
                    )
                })}
                </Slider>
            </section>
            )}
        <section className="showroom">
            <section className="showroom--prices-container">
                <h3>Priser</h3>
                <section className="showroom--prices">
                    <Price top="3 timer" price={data.directusShowroom.pris_1} priceText="pr. person + moms" incl="kaffe / vand" extra={"*"}/>
                    <Price top="Hel dag" price={data.directusShowroom.pris_2} priceText="pr. person + moms" incl="kaffe / vand" extra={"*"}/>
                </section>
                <p className="showroom--prices-info"><sup>*</sup>{data.directusShowroom.pris_extra}</p>
            </section>
            <section className="showroom--contact-block">
                <h3>Kontakt os</h3>
                <form data-netlify="true" method="POST" name="showroom" netlify-honeypot="bot-field" netlify action="/thank-you">
                    <div>
                        <p style={{visibility: 'hidden', position: 'absolute'}}>
                            <label htmlFor="bot">Don’t fill this out if you're human: <input id="bot" name="bot-field" aria-label="Don’t fill this out if you're human:"/></label>
                        </p>
                        <Input name="name" type="text" required={true}>Dit navn</Input>
                        <Input name="email" type="email" required={true}>Din emailadresse</Input>
                        <Input name="phone" type="text" required={true}>Dit telefonnummer</Input>
                        <Input name="virksomhed" type="text">Virksomhed</Input>
                    </div>
                    <div>
                        <div className="horizontal">
                            <Input name="date" type="date">Dato</Input>
                            <Input name="person" type="number" other={{min: "1"}}>Antal personer</Input>
                        </div>
                        <div className="horizontal mb--input-radio">
                            <span>Længde</span>
                            <Input name="length" type="radio" other={{value: "3 timer"}}>3 timer</Input>
                            <Input name="length" type="radio" other={{value: "Hel dag"}}>Hel dag</Input>
                            <Input name="length" type="radio" other={{value: "Anden"}}>Anden</Input>
                        </div>
                        <div className="mb--input-block">
                            <label htmlFor="comments">Ekstra kommentarer<textarea name="text" id="comments" rows="8" aria-label="Ekstra kommentarer"></textarea></label>
                        </div>
                    </div>
                    <input type="hidden" name="form-name" value="showroom" aria-label="Hidden: showroom"/>
                    <button type="submit">SEND</button>


                </form>
            </section>
        </section>


    </>
  )
}

    export const query = graphql`
    query {
        directusShowroom {
            catch_text
            main_image {
                localFile {
                    childImageSharp {
                        fluid(quality: 70) {
                            ...GatsbyImageSharpFluid_withWebp            
                        }
                    }
                }
            }
            text
            pris_1
            pris_2
            pris_extra
            gallery {
                name
                image {
                    localFile {
                        childImageSharp {
                            fluid(quality: 70, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp            
                            }
                        }
                    }
                }
            }
        }
    }
`
